import { createTheme } from "@mui/material";

// const theme = createTheme({
//     components: {
//         MuiPopover: {
//             defaultProps: {
//                 container: shadowRootElement,
//             },
//         },
//         MuiPopper: {
//             defaultProps: {
//                 container: shadowRootElement,
//             },
//         },
//         MuiModal: {
//             defaultProps: {
//                 container: shadowRootElement,
//             },
//         },

//         MuiPaper: {

//         }
//     },
// })

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0C0833', // Primary branding color
        },
        secondary: {
            main: '#FF2E57', // Secondary color
        },
        error: {
            main: '#FF1744', // Error color
        },
        warning: {
            main: '#FFD600', // Warning color
        },
        info: {
            main: '#2979FF', // Info color
        },
        success: {
            main: '#4CAF50', // Success color
        },
        text: {
            primary: '#333333', // Primary text color
        },
        background: {
            paper: '#FFFFFF', // Background color for paper surfaces
            default: '#F5F5F5', // Default background color
        },
        divider: '#BDBDBD', // Divider color
    },
});
