import React, { useState, useEffect, Component } from 'react';
import Datatables from '../../DataTable/Datatables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from 'react-hook-form';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';
import Swal from "sweetalert2";


const SeoPricing = () => {

    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [id, setId] = useState('')
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        plan: '',
        price: '',
        content: '',
    });
    const [formDataUpdate, setFormDataUpdate] = useState({
        title: '',
        plan: '',
        price: '',
        content: '',
    });

    const getData = async () => {
        try {
            const priceData = await Axios.get('/api/pricing');
            let price = priceData.data.data;
            setData(price);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setPending(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);


    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData({
            title: '',
            plan: '',
            price: '',
            content: '',
        })
        setId('');
        reset({
            title: '',
            plan: '',
            price: '',
            content: '',
        })
    };


    const showUpdateModal = (row) => {
        setShowUpdate(true);
        getPrice(row._id);
        setId(row._id);
    };


    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setFormDataUpdate({
            title: '',
            plan: '',
            price: '',
            content: '',
        })
        reset1({
            title: '',
            plan: '',
            price: '',
            content: '',
        })
        setId('');
    };




    const getPrice = async (_id) => {
        try {
            const priceData = await Axios.get(`/api/pricing/${_id}`);
            let price = priceData.data;
            if (price) {
                setFormDataUpdate({
                    title: price.title,
                    plan: price.plan,
                    price: price.price,
                    content: price.content,
                })
                reset1({
                    title: price.title,
                    plan: price.plan,
                    price: price.price,
                    content: price.content,
                })

            }
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }


    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            {
                name: <th className='fs-6 '>Title</th>,
                selector: (row) => row.title,
                width: '200px',
            },
            {
                name: <th className='fs-6 '>Plan</th>,
                selector: (row) => row.plan,
                width: '200px',
            },
            {
                name: <th className='fs-6'>Price</th>,
                selector: (row) => row.price,
                width: '220px',
            },
            {
                name: <th className='fs-6'>Content</th>,
                selector: (row) => row.content,
                width: '220px',
            },
            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        {/* <Link to={`/service-update/${row._id}`}> */}
                        <Button className='Add_btn_ text-white'
                            onClick={() => showUpdateModal(row)}
                            variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}>
                            <FaEdit />
                        </Button>
                        <Button className='_delete_btn_ text-white bg-danger' variant='contained'
                            onClick={() => confirmDelete(row._id)}
                        ><FaTrashAlt /></Button>
                        {/* </Link> */}

                    </div>
                ),
                right: true,
                width: '180px',

            },
        ],
    };

    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };


    const handleInputChange1 = (fieldName, value) => {
        setFormDataUpdate((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm();

    const onSubmit = async () => {
        // setPending(true);

        try {
            const response = await Axios.post(`/api/pricing`, formData);
            if (response.status === 200 || 201) {
                notify('Plan Added Successfully');
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }

    };


    const onUpdate = async () => {
        // setPending(true);

        try {
            const response = await Axios.put(`/api/pricing/${id}`, formDataUpdate);
            if (response.status === 200 || 201) {
                notify('Update Successfully');
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }

    };


    const handleDelete = async (id) => {
        try {
            const response = await Axios.delete(`/api/pricing/${id}`);
            if (response.status === 200) {
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }
            Swal.fire({
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                icon: 'success',
                confirmButtonColor: '#0C0833',
            });
        } catch (error) {
            console.error(error);

            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the data.',
                icon: 'error',
            });
        }
    };

    const confirmDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0C0833',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {

            if (result.value) {
                console.log(result)
                handleDelete(id);
            }
        });
    };


    return (
        <>
            <div className='service-page'>
                <Helmet>
                    <title>SEO Pricing</title>
                </Helmet>

                <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                    <ToastContainer />
                    <section className="wrapper _blogs">
                        <div className="row">
                            <div className="col-lg-6">
                                <span className='p-3 fw-bold'>SEO Pricing</span>
                            </div>

                            <div className='col-lg-6 pb-2 btn-d d-flex justify-content-end'>
                                <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }}
                                    onClick={handleShow}
                                >
                                    Add Plan
                                </Button>
                            </div>
                        </div>
                    </section>

                </section>

                <section className=' mt-2'>
                    <Card >
                        <div className="main " >
                            <Datatables data={data} columns={tableData.columns} />
                        </div>
                    </Card>

                </section>


            </div>


            {/*Add Modal */}
            <Modal show={show} onHide={handleClose} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Add Plan</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Body>

                        <Form.Group className="mb-3" >
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" value={formData.title}
                                {...register("title", {
                                    required: true,
                                    onChange: (e) => handleInputChange('title', e.target.value)
                                })}
                            />

                            {errors.title && (
                                <div className='errMsg text-danger'>title is required</div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Plan</Form.Label>
                            <Form.Control type="text" placeholder="Plan" value={formData.plan}
                                {...register("plan", {
                                    required: true,
                                    onChange: (e) => handleInputChange('plan', e.target.value)
                                })}
                            />

                            {errors.plan && (
                                <div className='errMsg text-danger'>plan is required</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="text" placeholder="Price" value={formData.price}
                                {...register("price", {
                                    required: true,
                                    onChange: (e) => handleInputChange('price', e.target.value)
                                })}
                            />

                            {errors.price && (
                                <div className='errMsg text-danger'>Price is required</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>content</Form.Label>
                            <Form.Control type="text" placeholder="Content" id="content" value={formData.content}
                                {...register("content", {
                                    required: true,
                                    onChange: (e) => handleInputChange('content', e.target.value)
                                })}
                            />

                            {errors.content && (
                                <div className='errMsg text-danger'>content is required</div>
                            )}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='submit' className='text-white' variant='contained' style={{ backgroundColor: '#0C0833' }} >
                            Add
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>


            {/* update */}
            <Modal show={showUpdate} onHide={handleCloseUpdate} style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>
                    <Modal.Title>Update Plan</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit1(onUpdate)} autoComplete="off">
                    <Modal.Body>
                        <Form.Group className="mb-3" >
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" value={formDataUpdate.title}
                                {...register1("title", {
                                    required: true,
                                    onChange: (e) => handleInputChange1('title', e.target.value)
                                })}
                            />

                            {errors1.title && (
                                <div className='errMsg text-danger'>title is required</div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Plan</Form.Label>
                            <Form.Control type="text" placeholder="Plan" value={formDataUpdate.plan}
                                {...register1("plan", {
                                    required: true,
                                    onChange: (e) => handleInputChange1('plan', e.target.value)
                                })}
                            />

                            {errors1.plan && (
                                <div className='errMsg text-danger'>plan is required</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="text" placeholder="Price" value={formDataUpdate.price}
                                {...register1("price", {
                                    required: true,
                                    onChange: (e) => handleInputChange1('price', e.target.value)
                                })}
                            />

                            {errors1.price && (
                                <div className='errMsg text-danger'>Price is required</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>content</Form.Label>
                            <Form.Control type="text" placeholder="Content" id="content" value={formDataUpdate.content}
                                {...register1("content", {
                                    required: true,
                                    onChange: (e) => handleInputChange1('content', e.target.value)
                                })}
                            />

                            {errors1.content && (
                                <div className='errMsg text-danger'>content is required</div>
                            )}
                        </Form.Group>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="contained" className='text-white' style={{ backgroundColor: '#0C0833' }}  >
                            Update
                        </Button>
                        <Button variant="secondary" onClick={handleCloseUpdate}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default SeoPricing