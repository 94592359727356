import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TextEditor from '../Editor/TextEditor';
import { useParams } from 'react-router-dom';
import { Axios } from '../components/common/Axios';

const ThankyouUpdate = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const { id } = useParams();
    const [heading, setHeading] = useState('');
    const [image, setImage] = useState('');
    const [updatedImage, setUpdatedImage] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');
    const [video, setVideo] = useState('');
    const [updatedVideo, setUpdatedVideo] = useState('');
    const [btn_text, setBtn_text] = useState('');
    const [btn_url, setBtn_url] = useState('');
    const [pending, setPending] = useState(true);

    const handleClose = () => {
        setHeading('');
        setUpdatedContent('');
        setUpdatedImage('');
        setVideo('');
        setBtn_text('');
        setBtn_url('');

    }

    const getData = () => {
        Axios.get(`/api/thankyou/${id}`)
            .then((response) => {
                const thankyouData = response.data;
                console.log('thankyouData');
                if (thankyouData) {

                    setHeading(thankyouData.heading);
                    setImage(thankyouData.image);
                    setVideo(thankyouData.video);
                    setUpdatedContent(thankyouData.content);
                    setBtn_text(thankyouData.btn_text);
                    setBtn_url(thankyouData.btn_url);


                }
            })
            .catch((err) => {
                console.log(err);
            });

        // api/section/sub-service/section/
    };

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, []);



    const updatethankyouSuccess = () => toast.info("thankyou page Updated Successfully", { position: "top-center", autoClose: 2000, });
    const updatethankyouError = () => toast.error("Something went wrong", { position: "top-center", autoClose: 2000, });
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onUpdate = (thankyouData) => {
        setPending(true);

        const formData = new FormData();
        // formData.append("file", updatedImage);

        Axios.put(`/api/thankyou/${id}`, {

            heading: heading,
            image: updatedImage,
            video: updatedVideo,
            content: updatedContent,
            btn_text: btn_text,
            btn_url: btn_url,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    handleClose();
                    updatethankyouSuccess();
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 1500);
                }
            })
            .catch((err) => {
                updatethankyouError();
                console.error(err);
            });
    }
    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>Update ThankyouPage</title>
                </Helmet>
                <section className="wrapper _blogs">
                    <div className="row">
                        <div className="col-lg-6">
                            <span className='p-3 fw-bold'>Update ThankyouPage</span>
                        </div>

                    </div>
                </section>
                <section className='card mt-2'>
                    <div className='blog-d' >
                        <ToastContainer />
                        <Form className='row m-2' onSubmit={handleSubmit(onUpdate)}>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Heading</Form.Label>
                                <Form.Control type="text" placeholder="Heading" id="heading" value={heading}
                                    {...register("Heading", {
                                        required: false,
                                        onChange: (e) => setHeading(e.target.value)
                                    })}
                                />

                                {errors.Heading && (
                                    <div className='errMsg text-danger'>Heading is required</div>
                                )}

                            </Form.Group>

                            <Form.Group
                                className="mb-3"

                            >
                                {(image && image !== undefined && image != '') &&
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Form.Label>Image</Form.Label>
                                            <Form.Control type="file" id="file"
                                                {...register("Image", {
                                                    required: false,
                                                    onChange: (e) => setUpdatedImage(e.target.files[0])
                                                })}
                                            />

                                            {errors.Image && (
                                                <div className='errMsg text-danger'>Please Select Image</div>
                                            )}

                                        </div>

                                        <div className='col-lg-6 text-center mb-3 mt-3'>
                                            <Form.Label>Image Preview</Form.Label>
                                            <div className='p-3'>

                                                {updatedImage == "" ?

                                                    <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                    :
                                                    <img src={URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(video && video !== undefined && video != '') &&
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Form.Label>Video</Form.Label>
                                            <Form.Control
                                                type="file"
                                                placeholder="Video"
                                                {...register("Video", {
                                                    required: false,
                                                    onChange: (e) => setUpdatedVideo(e.target.files[0])
                                                })}
                                            />

                                            {errors.Video && (
                                                <div className='errMsg text-danger'>Video is required</div>
                                            )}
                                        </div>

                                        <div className='col-lg-6 text-center mb-3 mt-3'>
                                            <Form.Label>Media Preview</Form.Label>
                                            <div className='p-3'>
                                                {updatedVideo === "" ?
                                                    <video src={`${apiUrl}/static/assets/${video}`} controls style={{ maxWidth: "300px", maxHeight: "300px" }} /> :
                                                    <video src={URL.createObjectURL(updatedVideo)} controls style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Form.Group>


                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Btn_text</Form.Label>
                                <Form.Control type="text" placeholder="btn_text" id="btn_text" value={btn_text}
                                    {...register("btn_text", {
                                        required: false,
                                        onChange: (e) => setBtn_text(e.target.value)
                                    })}
                                />

                                {errors.Btn_text && (
                                    <div className='errMsg text-danger'>Btn_text is required</div>
                                )}

                            </Form.Group>


                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Btn_url</Form.Label>
                                <Form.Control type="text" placeholder="btn_url" id="btn_url" value={btn_url}
                                    {...register("btn_url", {
                                        required: false,
                                        onChange: (e) => setBtn_url(e.target.value)
                                    })}
                                />

                                {errors.Btn_url && (
                                    <div className='errMsg text-danger'>Heading is required</div>
                                )}

                            </Form.Group>


                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white' variant='contained'>
                                    Update ThankyouPage
                                </Button>
                            </div>
                        </Form>
                    </div>

                </section>
                {/* 
                <button onClick={handleDelete}>
         Delete
      </button> */}


            </div>
        </>
    )
}

export default ThankyouUpdate