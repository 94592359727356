import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';


const Ranking = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [pending, setPending] = useState(false)
    const [id, setId] = useState('');
    const [image, setImage] = useState('')
    const [formData, setFormData] = useState({
        title: '',
        heading: '',
        content: '',
        description: '',
        cta: ''
    })

    const getData = async () => {
        try {
            const rankingData = await Axios.get(`/api/section/seo/ranking`);
            let ranking = rankingData.data.data;
            if (ranking) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ['title']: ranking.title,
                    ['heading']: ranking.heading,
                    ['content']: ranking.content,
                    ['description']: ranking.description,
                    ['cta']: ranking.cta,
                }))
                setId(ranking._id);
                setImage(ranking.image);
            }

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setPending(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);


    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };


    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async () => {
        setPending(true);

        try {
            const response = await Axios.put(`/api/section/seo/ranking/${id}`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Update Successfully');
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }

    };
    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>SEO ranking</title>
                </Helmet>
                <section className="wrapper _profile">
                    <div className="row">
                        <ToastContainer />
                        <div className="col-lg-12">
                            <span className='p-3 fw-bold'>SEO ranking</span>
                        </div>
                    </div>
                </section>
                <section classNAme="card  cardSectionBorder mt-3">
                    <div className='m-2'>
                        <Form className='row m-0' onSubmit={handleSubmit(onSubmit)}>



                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.title}
                                    {...register("title", {
                                        required: false,
                                        onChange: (e) => handleInputChange('title', e.target.value)
                                    })}
                                />

                                {errors.title && (
                                    <div className='errMsg text-danger'>title is required</div>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Heading</Form.Label>
                                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.heading}
                                    {...register("Heading", {
                                        required: false,
                                        onChange: (e) => handleInputChange('heading', e.target.value)
                                    })}
                                />

                                {errors.Heading && (
                                    <div className='errMsg text-danger'>Heading is required</div>
                                )}

                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                <Form.Label>CTA</Form.Label>
                                <Form.Control type="text" placeholder='CTA' value={formData.cta}
                                    {...register("CTA", {
                                        required: false,
                                        onChange: (e) => handleInputChange(e.target.value)
                                    })}
                                />

                                {errors.CTA && (
                                    <div className='errMsg text-danger'>CTA is required</div>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <Form.Control as="textarea" placeholder="Content" rows={7}
                                    value={formData.content}
                                    onChange={(e) => handleInputChange('content', e.target.value)} />

                            </Form.Group>


                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" placeholder="description" rows={7}
                                    value={formData.description}
                                    onChange={(e) => handleInputChange('description', e.target.value)} />

                            </Form.Group>
                            <Form.Group
                                className="mb-3" >

                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <Form.Label>Image</Form.Label>
                                        <Form.Control type="file" id="file"
                                            {...register("Image", {
                                                required: false,
                                                onChange: (e) => handleInputChange('image', e.target.files[0])
                                            })}
                                        />

                                        {errors.Image && (
                                            <div className='errMsg text-danger'>Please Select Image</div>
                                        )}

                                    </div>

                                    <div className='col-lg-6 text-center mb-3 mt-3'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            {!formData.image ?

                                                <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                :
                                                <img src={URL.createObjectURL(formData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                            }
                                        </div>

                                    </div>
                                </div>

                            </Form.Group>
                            <hr></hr>
                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className=' Add_btn_ text-white' variant='contained'>
                                    Update
                                </Button>
                            </div>
                        </Form>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Ranking