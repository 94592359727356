import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TextEditor from '../../Editor/TextEditor';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BlogAdd = () => {

  const navigate = useNavigate();

  const [updatedContent, setUpdatedContent] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    heading: '',
    image: '',
    author: '',
    content: '',
    description: '',
    seo_title: '',
    canonical: '',
    robot: '',
    script: '',
    feature_image: '',
    seo_description: '',
  })

  const handleClose = () => {
    setFormData({
      title: '',
      heading: '',
      image: '',
      author: '',
      content: '',
      description: '',
      seo_title: '',
      canonical: '',
      robot: '',
      script: '',
      feature_image: '',
      seo_description: '',
    });
    reset({
      title: '',
      heading: '',
      image: '',
      author: '',
      content: '',
      description: '',
      seo_title: '',
      canonical: '',
      robot: '',
      script: '',
      feature_image: '',
      seo_description: '',
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {

    handleInputChange('content', updatedContent);
    handleInputChange('description', updatedDescription);
  }, [updatedContent, updatedDescription])


  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };


  // const addClientSuccess = () => toast.info("Client Added Successfully", { position: "top-center", autoClose: 5000, });

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = async () => {
    try {
      const response = await Axios.post('/api/blog', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      if (response.status === 200 || 201) {
        notify('blog added Successfully');
        handleClose();
        setTimeout(() => {
          navigate('/blog')
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    }
  }



  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Add Blog</title>
        </Helmet>
        <section className="wrapper _blogs">
          <div className="row">
            <div className="col-lg-6">
              <span className='p-3 fw-bold'>Add Blog</span>
            </div>

          </div>
        </section>
        <section className='card mt-2'>
          <div className='blog-d' >
            <ToastContainer />
            <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Title" id="title" value={formData.title}
                  {...register("Title", {
                    required: true,
                    onChange: (e) => handleInputChange('title', e.target.value)
                  })}
                />

                {errors.Title && (
                  <div className='errMsg text-danger'>Title is required</div>
                )}

              </Form.Group>


              <Form.Group
                className="mb-3"

              >

                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="file" name="file"
                      {...register("Image", {
                        required: true,
                        onChange: (e) => handleInputChange('image', e.target.files[0])
                      })}
                    />

                    {errors.Image && (
                      <div className='errMsg text-danger'>Please Select Image</div>
                    )}

                  </div>

                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      {formData.image == "" ?

                        <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        :
                        <img src={URL.createObjectURL(formData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                      }
                    </div>

                  </div>
                </div>

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Author</Form.Label>
                <Form.Control type="text" id="authore" value={formData.author}
                  {...register("Author", {
                    required: true,
                    onChange: (e) => handleInputChange('author', e.target.value)
                  })}
                />

                {errors.Author && (
                  <div className='errMsg text-danger'>Please Enter Author Name</div>
                )}

              </Form.Group >

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Description</Form.Label>
                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
              </Form.Group>

              <div className="col-lg-12">
                <span className=' fw-bold'>SEO Section</span>
              </div>

              <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>SEO Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="SEO Title"
                  value={formData.seo_title}
                  {...register("Seo_title", {
                    required: false,
                    onChange: (e) => handleInputChange('seo_title', e.target.value)
                  })}
                />

                {errors.Seo_title && (
                  <div className='errMsg text-danger'>SEO title is required</div>
                )}
              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Canonical</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Canonical"
                  value={formData.canonical}
                  onChange={(e) => handleInputChange('canonical', e.target.value)}

                />

              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label htmlFor="Robot">Robot</Form.Label>
                <Form.Select
                  id="Robot"
                  aria-label="Default select example"
                  value={formData.robot}
                  onChange={(e) => handleInputChange('robot', e.target.value)}
                >
                  <option value="" >Select</option>
                  <option value="index,follow" >Index,Follow</option>
                  <option value="index,no-follow">Index,No-Follow</option>
                  <option value="no-index,follow">No-Index,Follow</option>
                  <option value="no-index,no-follow">No-Index,No-Follow</option>
                </Form.Select>

              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Script</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Script"
                  value={formData.script}
                  onChange={(e) => handleInputChange('script', e.target.value)}

                />

              </Form.Group>

              <Form.Group
                className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12"
              >

                <Form.Label>Feature Image</Form.Label>
                <Form.Control type="url" name="file" value={formData.feature_image}
                  onChange={(e) => handleInputChange('feature_image', e.target.value)} />


              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>SEO Description</Form.Label>
                <Form.Control as="Textarea" placeholder="SEO Description"
                  value={formData.seo_description}
                  onChange={(e) => handleInputChange('seo_description', e.target.value)} />

              </Form.Group>

              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white' variant='contained'>
                  Add Blog
                </Button>
              </div>
            </Form>
          </div>

        </section>




      </div>
    </>
  )
}

export default BlogAdd