import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TextEditor from '../Editor/TextEditor';
import { useParams } from 'react-router-dom';
import { Axios } from '../components/common/Axios';


const PopupUpdate = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [btn_text, setBtntext] = useState('');
  const [btn_link, setBtnlink] = useState('');
  const [delay, setDelay] = useState('');
  const [updatedImage, setImage] = useState('');
  const [image, setImageup] = useState('');
  const [addContent, setAddedContent] = useState('');
  const [type, setType] = useState('');
  const [show, setShow] = useState(false);


  const [file, setFile] = useState('');
  const [pending, setPending] = useState(true);
  const handleClose = () => {
    setTitle('');
    setBtntext('');
    setBtnlink('');
    setImage('');
    setAddedContent('');
    setType('');
    setDelay('');
    setShow(false);
  }

  const updatePopupError = () => toast.error("Something went wrong", { position: "top-center", autoClose: 5000 });

  const updatePopupSuccess = () => toast.info("Popup Updated Successfully", { position: "top-center", autoClose: 5000 });
  const { register, handleSubmit, formState: { errors } } = useForm();



  const getPopupData = () => {
    Axios.get(`/api/popup/${id}`)
      .then((response) => {
        const popupData = response.data;
        if (popupData) {
          setTitle(popupData.title);
          setBtntext(popupData.btn_text);
          setBtnlink(popupData.btn_link);
          setImageup(popupData.image);
          setAddedContent(popupData.content);
          setType(popupData.type);
          setDelay(popupData.delay);


        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPopupData();
    window.scrollTo(0, 0);
  }, [id]);

  const onUpdate = (PopupData) => {
    setPending(true);

    const formData = new FormData();
    formData.append("file", updatedImage);


    Axios.put(`/api/popup/${id}`, {
      title: title,
      btn_text: btn_text,
      btn_link: btn_link,
      image: updatedImage,
      content: addContent,
      delay: delay,
      type: type
    }, {
      headers: {

        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          updatePopupSuccess();
          setTitle('');
          setBtntext('');
          setBtnlink('');
          setImage('');
          setAddedContent('');
          setType('');
          setDelay('');
          handleClose();
          setTimeout(() => {
            window.location.reload(true);
          }, 1500);
        }
      })
      .catch((err) => {
        updatePopupError();
        console.error(err);
      });
  };

  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Update Popup</title>
        </Helmet>
        <section className="wrapper _blogs">
          <div className="row">
            <ToastContainer />
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>Update Popup</span>
            </div>
          </div>
        </section>
        <section className='card mt-2'>
          <div className='blog-d' >
            <Form className='row m-2' onSubmit={handleSubmit(onUpdate)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Title" id="title" value={title}
                  {...register("Title", {
                    required: false,
                    onChange: (e) => setTitle(e.target.value)
                  })}
                />

                {errors.Title && (
                  <div className='errMsg text-danger'>Title is required</div>
                )}

              </Form.Group>


              <Form.Group
                className="mb-3"

              >

                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="file" id="file"
                      {...register("Image", {
                        required: false,
                        onChange: (e) => setImage(e.target.files[0])
                      })}
                    />

                    {errors.Image && (
                      <div className='errMsg text-danger'>Please Select Image</div>
                    )}

                  </div>

                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      {updatedImage == "" ?

                        <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        :
                        <img src={URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                      }
                    </div>

                  </div>
                </div>

              </Form.Group>


              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={addContent}
                  {...register("content", {
                    required: true,
                    onChange: (e) => setAddedContent(e.target.value)
                  })}
                />
              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label htmlFor="Type">Type</Form.Label>
                <Form.Select
                  id="type"
                  aria-label="Default select example"
                  value={type}
                  {...register("type", {
                    required: true,
                    onChange: (e) => setType(e.target.value)
                  })}

                >
                  <option value="" >Select Type</option>
                  <option value="1">Form</option>
                  <option value="2">Button</option>


                </Form.Select>
                {errors.type && (
                  <div className='errMsg text-danger'>type is required</div>
                )}
              </Form.Group>
              {type === "2" && (
                <Form.Group className="mb-3">
                  <Form.Label>Btn_Text</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Text"
                    value={btn_text}
                    onChange={(e) => setBtntext('btn_text', e.target.value)}
                  />
                  <Form.Label>Btn_Link</Form.Label>
                  <Form.Control
                    type="link"
                    placeholder="link"
                    value={btn_link}
                    onChange={(e) => setBtnlink('btn_link', e.target.value)}
                  />
                </Form.Group>

              )}
              <Form.Group className="mb-3 col-12">
                <Form.Label>Delay</Form.Label>
                <Form.Control type="delay" placeholder="delay" id="delay" value={delay}
                  {...register("delay", {
                    required: true,
                    onChange: (e) => setDelay(e.target.value)
                  })}
                />

                {errors.Delay && (
                  <div className='errMsg text-danger'>Delay is required</div>
                )}

              </Form.Group>

              {/* <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Btn_Text</Form.Label>
                <Form.Control type="text" placeholder="Btn_Text" id="btn_text"
                  value={btn_text}
                  {...register("Btn_Text", {
                    required: false,
                    onChange: (e) => setBtntext(e.target.value)
                  })}
                />

                {errors.Btn_Text && (
                  <div className='errMsg text-danger'>btn text is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Btn_Link</Form.Label>
                <Form.Control type="text" placeholder="Btn_Link" id="btn_link"
                  value={btn_link}
                  {...register("Btn_Link", {
                    required: false,
                    onChange: (e) => setBtnlink(e.target.value)
                  })}
                />

                {errors.Btn_Link && (
                  <div className='errMsg text-danger'>btn link is required</div>
                )}

              </Form.Group> */}


              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white' variant='contained'>
                  Update Popups
                </Button>
              </div>
            </Form>
          </div>

        </section>

      </div>
    </>
  )
}

export default PopupUpdate