import React, { useState, useEffect } from 'react';
import Datatables from '../../DataTable/Datatables';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import { notify, notifyErr } from './norify';
import { Axios } from './Axios';

const SubContentModel = ({ _id, type, services }) => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [pending, setPending] = useState(true);
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false)
    const [id, setId] = useState('');
    const [slug, setSlug] = useState('')
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        image: '',
    })
    const [data, setData] = useState([])
    const [image, setImage] = useState('');
    const [updateSlug, setUpdateSlug] = useState('')
    const [updateFormData, setUpdateFormData] = useState({
        title: '',
        content: '',
        image: '',
    })

    const handleShow = (row) => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData({
            title: '',
            content: '',
            image: '',
        })
        reset({
            title: '',
            content: '',
            image: '',
        })
        setSlug('');
    };


    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setUpdateFormData({
            title: '',
            content: '',
            image: '',
        })
        reset1({
            title: '',
            content: '',
            image: '',
        })
        setId('');
        setUpdateSlug('');
    }
    const handleShowUpdate = (row) => {
        setShowUpdate(true)
        setId(row._id)
        getContent(row._id);
    }

    const getData = async () => {
        if (_id != undefined) {
            try {
                const contentData = await Axios.get(`/api/${_id}/${type}/sub-content`);
                let content = contentData.data.data;
                setData(content);

            } catch (error) {
                // Handle errors
                console.error('Error:', error);
            } finally {
                setPending(false)
            }
        }
    }

    useEffect(() => {
        getData();
    }, [_id, type]);

    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const handleInputChange1 = (fieldName, value) => {
        setUpdateFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };



    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '150px',
            },
            {
                name: <th className='fs-6 '>Title</th>,
                selector: (row) => row.title,
                width: '250px',
            },
            {
                name: <th className='fs-6 '>Content</th>,
                selector: (row) => row.content,
                width: '350px',
            },
            {
                name: <th className='fs-6 '>Image</th>,
                selector: (row) => (
                    <img width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.image}`} alt="Portfolio Image" />
                ),
                width: '200px',
            },
            {
                name: <th className='fs-6 '>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>

                        <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}
                            onClick={() => handleShowUpdate(row)}
                        >
                            <FaEdit />
                        </Button>

                        <Button className='_delete_btn_ text-white  bg-danger' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}
                            onClick={() => confirmDelete(row._id)}
                        ><FaTrashAlt /></Button>
                    </div>
                ),
                right: true,
            },
        ],
    };



    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = async () => {
        setPending(true);

        try {
            const response = await Axios.post(`/api/${_id}/${type}/sub-content`, slug !== '' ? { ...formData, slug: slug } : formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Update Successfully');
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }

    };

    const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm();
    const getContent = async (id) => {
        try {
            const content_Data = await Axios.get(`/api/${_id}/${type}/getsubcontent/${id}`);
            let content_ = content_Data.data.subContent;
            if (content_) {
                setUpdateFormData({
                    title: content_.title,
                    content: content_.content,
                })
                setImage(content_.image);
                setUpdateSlug(content_.slug);
            }
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };



    const onUpdate = async () => {
        setPending(true);
        try {
            const response = await Axios.put(`/api/${_id}/${type}/sub-content/${id}`, updateSlug !== '' ? { ...updateFormData, slug: updateSlug } : updateFormData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Sub Content updated Successfully');
                handleCloseUpdate();
                setTimeout(() => {
                    window.location.reload(false);
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        } finally {
            setPending(false)
        }
    };


    const handleDelete = async (id) => {
        try {
            const response = await Axios.delete(`/api/${_id}/${type}/deletesubcontent/${id}`);
            if (response.status === 200) {
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }
            Swal.fire({
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                icon: 'success',
                confirmButtonColor: '#0C0833',
            });
        } catch (error) {
            console.error(error);

            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the data.',
                icon: 'error',
            });
        }
    };

    const confirmDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0C0833',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {

            if (result.value) {
                console.log(result)
                handleDelete(id);
            }
        });
    };



    return (
        <>

            <div className='profile-page mt-5'>

                <section className="wrapper _blogs">
                    <div className="row m-0">
                        <div className="col-lg-6">
                            <span className='p-3 fw-bold'>Sub Content</span>
                        </div>
                        <div className='col-lg-6 px-2 btn-d '>

                            <div className=' pb-2 btn-d d-flex justify-content-end'>

                                <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }}
                                    onClick={handleShow}
                                >
                                    Add Sub Content
                                </Button>

                            </div>
                        </div>
                    </div>
                </section>

                <section className='mt-2'>
                    <Card>
                        <div className="main" >
                            <Datatables data={data} columns={tableData.columns} />
                        </div>
                    </Card>
                </section>


            </div>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Add Sub Content</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Title" value={formData.title}
                            {...register("title", {
                                required: true,
                                onChange: (e) => handleInputChange('title', e.target.value)
                            })}
                        />

                        {errors.title && (
                            <div className='errMsg text-danger'>title is required</div>
                        )}

                    </Form.Group>

                    {/* services */}
                    {type == 6 && services?.length > 0 &&
                        <Form.Group className=" mb-3 col-12">
                            <Form.Label>Selected Service</Form.Label>
                            <Form.Select
                                aria-label="Default select example"
                                value={slug}

                                {...register("slug", {
                                    required: true,

                                    onChange: (e) => setSlug(e.target.value)
                                })}
                            >
                                <option value="" >Select Service</option>
                                {services?.map((service, index) => (
                                    <option value={service.slug} key={index}>{service.name}</option>
                                ))}


                            </Form.Select>
                            {errors.ParentService && (
                                <div className='errMsg text-danger'>Service is required</div>
                            )}
                        </Form.Group>
                    }

                    <Form.Group
                        className="mb-3"
                    >

                        <div className='row'>
                            <div className='col-12'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control type="file"
                                    {...register("Image", {
                                        required: true,
                                        onChange: (e) => handleInputChange('image', e.target.files[0])
                                    })}
                                />

                                {errors.Image && (
                                    <div className='errMsg text-danger'>Please Select Image</div>
                                )}

                            </div>

                            <div className='col-12 text-center mb-3 mt-3'>
                                <Form.Label>Image Preview</Form.Label>
                                <div className='p-3'>

                                    {formData.image == "" ?

                                        <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                        :
                                        <img src={URL.createObjectURL(formData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                    }
                                </div>
                            </div>
                        </div>

                    </Form.Group>


                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label>Content</Form.Label>
                        <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={formData.content}
                            {...register("content", {
                                required: true,
                                onChange: (e) => handleInputChange('content', e.target.value)
                            })}
                        />
                    </Form.Group>


                    <Modal.Footer>
                        <Button type="submit" variant="contained" className='text-white' style={{ backgroundColor: '#0C0833' }}  >
                            Add
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            {/* update */}
            <Modal show={showUpdate} onHide={handleCloseUpdate} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Update Sub Content</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit1(onUpdate)}>
                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Title" value={updateFormData.title}
                            {...register1("updateTitle", {
                                required: false,
                                onChange: (e) => handleInputChange1('title', e.target.value)
                            })}
                        />

                        {errors1.updateTitle && (
                            <div className='errMsg text-danger'>Title is required</div>
                        )}

                    </Form.Group>

                    {type == 6 && services?.length > 0 &&
                        <Form.Group className=" mb-3 col-12">
                            <Form.Label>Selected Service</Form.Label>
                            <Form.Select
                                aria-label="Default select example"
                                value={updateSlug}

                                {...register1("updateSlug", {
                                    required: true,

                                    onChange: (e) => setUpdateSlug(e.target.value)
                                })}
                            >
                                <option value="" >Select Service</option>
                                {services?.map((service, index) => (
                                    <option value={service.slug} key={index}>{service.name}</option>
                                ))}


                            </Form.Select>
                            {errors.ParentService && (
                                <div className='errMsg text-danger'>Service is required</div>
                            )}
                        </Form.Group>
                    }

                    <Form.Group
                        className="mb-3"
                    >

                        <div className='row'>
                            <div className='col-12'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control type="file"
                                    {...register1("updateImage", {
                                        required: false,
                                        onChange: (e) => handleInputChange1('image', e.target.files[0])
                                    })}
                                />

                                {errors1.Image && (
                                    <div className='errMsg text-danger'>Please Select Image</div>
                                )}

                            </div>

                            <div className='col-12 text-center mb-3 mt-3'>
                                <Form.Label>Image Preview</Form.Label>
                                <div className='p-3'>

                                    {!updateFormData.image ?

                                        <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                        :
                                        <img src={URL.createObjectURL(updateFormData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                    }
                                </div>
                            </div>
                        </div>

                    </Form.Group>


                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label>Content</Form.Label>
                        <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={updateFormData.content}
                            {...register1("updateContent", {
                                required: false,
                                onChange: (e) => handleInputChange1('content', e.target.value)
                            })}
                        />
                    </Form.Group>

                    <Modal.Footer>
                        <Button type="submit" variant="contained" className='text-white' style={{ backgroundColor: '#0C0833' }}  >
                            Update
                        </Button>
                        <Button variant="secondary" onClick={handleCloseUpdate}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default SubContentModel