import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';

import { useParams } from 'react-router-dom';
import { Axios } from '../components/common/Axios';
import { notify, notifyErr } from '../components/common/norify';

const AboutUs = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const [id, setId] = useState('')
  const [updatedContent, setUpdatedContent] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [heading, setHeading] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');
  const [image, setImage] = useState('');
  const [pending, setPending] = useState(true);
  const handleClose = () => {
    setHeading('');
    setImage('');
    setUpdatedContent('');
    setUpdatedDescription('');
  }

  const getData = () => {
    Axios.get(`api/about`)
      .then((response) => {
        const aboutData = response.data.data;
        if (aboutData) {
          setId(aboutData._id)
          setHeading(aboutData.heading);
          setImage(aboutData.image);
          setUpdatedContent(aboutData.content);
          setUpdatedDescription(aboutData.description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = () => {
    setPending(true);

    Axios.put(`api/about/${id}`, {
      heading: heading,
      image: updatedImage,
      content: updatedContent,
      description: updatedDescription

    }, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then((response) => {
        if (response.status === 200) {
          handleClose();
          notify("Updated Successfully")
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .catch((err) => {
        notifyErr("Something went wrong")
        console.error(err);
      });

  };
  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>About us</title>
        </Helmet>
        <section className="wrapper _profile">
          <div className="row">
            <ToastContainer />
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>About Us</span>
            </div>
          </div>
        </section>
        <section classNAme="card  cardSectionBorder mt-3">
          <div className='row m-2'>
            <Form className='' onSubmit={handleSubmit(onSubmit)}>



              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Heading</Form.Label>
                <Form.Control type="text" placeholder="Heading" id="heading" value={heading}
                  {...register("Heading", {
                    required: false,
                    onChange: (e) => setHeading(e.target.value)
                  })}
                />

                {errors.Heading && (
                  <div className='errMsg text-danger'>Heading is required</div>
                )}

              </Form.Group>



              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Description</Form.Label>
                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
              </Form.Group>

              <Form.Group
                className="mb-3" >

                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="file" id="file"
                      {...register("Image", {
                        required: false,
                        onChange: (e) => setUpdatedImage(e.target.files[0])
                      })}
                    />

                    {errors.Image && (
                      <div className='errMsg text-danger'>Please Select Image</div>
                    )}

                  </div>

                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      {updatedImage == "" ?

                        <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        :
                        <img src={URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                      }
                    </div>

                  </div>
                </div>

              </Form.Group>
              <hr></hr>
              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className=' Add_btn_ text-white' variant='contained'>
                  Update
                </Button>
              </div>
            </Form>


          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
