import React from 'react';
import LoginForm from '../components/LoginForm';
import { styled } from '@mui/material/styles';
import logo from '../assets/technoidol.svg'
import Paper from '@mui/material/Paper';
// components

const Login = () => {

    const StyledContent = styled('div')(({ theme }) => ({
        maxWidth: 480,
        margin: 'auto',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(12, 0),
    }));

    return (
        <>
            <div className='w-100' style={{ background: '#f9faff' }}>
                <StyledContent>
                <Paper elevation={8} className='login_paper'>
                    <div className='logo-d mx-auto mb-4'>
                        <img src={logo} width={149} height={140} />
                    </div>
                    <LoginForm />
                    </Paper>
                </StyledContent>
                </div>
        </>
    )
}

export default Login