import React, { useState, useEffect } from 'react';
import Datatables from '../DataTable/Datatables';
// import SortIcon from "@material-ui/icons/ArrowDownward";
import { Link } from 'react-router-dom';
import { AiFillCodepenSquare } from 'react-icons/ai';
import { Axios } from '../components/common/Axios';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import { FaShareSquare, FaProjectDiagram } from 'react-icons/fa';
import { GrProjects } from "react-icons/gr";
import { MdMarkEmailUnread } from 'react-icons/md'
import EnquiryModel from '../components/common/EnqueryModel';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [countData, setCountData] = useState([])
  const [id, setId] = useState('')
  const [pending, setPending] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [filterBy, setFilterBy] = useState('0')

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    setId(row._id);
  };
  // const getnewsletter = () => {
  //   Axios.get('api/newsletter').then((response) => {
  //     console.log(response.data.data);
  //     setnewsletter(response.data.data);
  //     setPending(false);
  //   }).catch((err) => {
  //     setPending(false);
  //     console.log(err);
  //   })

  // }

  const getData = async () => {
    await Axios.get('/api/getAllCounts').then((response) => {
      if (response.status === 200) {
        setCountData(response.data.data)
      }
    }).catch((err) => {
      console.log(err);
    })

    try {
      const enquiryData = await Axios.get('/api/contact/enquiry');
      let enquiry = enquiryData.data.data;
      setData(enquiry);
      setNewData(enquiry)

    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    } finally {
      setPending(false)
    }
  }


  useEffect(() => {
    getData();
  }, []);


  const tableData = {
    columns: [
      {
        name: <th className='fs-6 ' >#</th>,
        cell: (row, index) => index + 1,
        width: '150px',
      },
      {
        name: <th className='fs-6 '>Name</th>,
        selector: (row) => row.name,
        width: '200px',
      },
      {
        name: <th className='fs-6'>Email</th>,
        selector: (row) => row.email,
        width: '220px',
      },
      {
        name: <th className='fs-6'>Phone</th>,
        selector: (row) => row.phone,
        width: '220px',
      },
      // {
      //   name: <th className='fs-6'>Status</th>,
      //   cell: (row, index) => row.status == 0 ? <span className='bg-info text-white p-1 rounded'>Pending</span> : row.status == 1 ? <span className=' bg-success text-white p-1 rounded'>Complete</span> : '-',
      //   width: '220px',
      // },
      {
        name: <th className='fs-6'>Action</th>,
        selector: (row) => (
          <div className='d-flex' style={{ gap: '10px' }}>

            <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}
              onClick={() => handleShowUpdate(row)}
            >
              <FaShareSquare />
            </Button>
          </div>
        ),
        right: true
      },

    ],
  };

  useEffect(() => {
    setData(newData?.filter(data => data.status == 0))
  }, [newData])




  // const filterByStatus = (status) => {
  //   if (status == "") {
  //     setData(newData);
  //   } else {
  //     setData(newData?.filter(data => data.status == status));
  //   }
  //   setFilterBy(status)
  // }

  return (
    <div>
      <title>Dashboard</title>
      <section id="main-content">
        <section className="wrapper">
          <div className="row">
            <div className="col-lg-12">
              <span className='fw-bold'>Dashboard</span>
              <section>
                <div className='dashboard_card my-4'>
                  <div className="row m-0 row-gap-3">
                    {countData?.map((count, index) => (
                      <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <Card className='text-white' style={{ backgroundColor: '#0C0833' }}>
                          <Card.Body className='p-4 d-flex justify-content-between align-items-center'>
                            <div>
                              <h1>{count.count}</h1>
                              <Card.Title>{count.name}</Card.Title>
                              <span>{count.name == "Enquiries" ? "(Pending)" : "(Active)"}</span>
                            </div>
                            {count.name == "service" && <GrProjects className='c_icon' style={{ fontSize: '45px' }} />}
                            {count.name == "subservice" && <FaProjectDiagram className='c_icon' style={{ fontSize: '45px' }} />}
                            {count.name == "Enquiries" && <MdMarkEmailUnread className='c_icon' style={{ fontSize: '45px' }} />}
                          </Card.Body>
                        </Card>

                      </div>
                    ))}
                  </div>
                </div>
              </section>

              <section>

                <div className="row m-0">
                  <div className="col-lg-6">
                    <span className='fw-bold'>Enquiries</span>
                    {/* <div>
                      <strong >Status :-</strong>
                      <select className="status select_status filter"
                        value={filterBy}
                        onChange={(e) => filterByStatus(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="0">Pending</option>
                        <option value="1">Complete</option>


                      </select>
                    </div> */}
                  </div>
                  <div className='col-lg-6 px-2 btn-d '>

                    <div className=' pb-2 btn-d d-flex justify-content-end'>
                      <Link to='/enquiry'>
                        <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }}
                        >
                          View More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <Card className='mt-2'>
                  <Card.Body>
                    <div className="main " >
                      <Datatables data={data?.slice(0, 5)} columns={tableData.columns} pending={pending} />
                    </div>
                  </Card.Body>
                </Card>
              </section>
            </div>
          </div>
        </section>
      </section>
      <EnquiryModel id={id} show={showUpdate} setShowUpdate={setShowUpdate} />
    </div>
  );
}

export default Dashboard;
