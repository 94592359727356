import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { set, useForm } from 'react-hook-form';
import TextEditor from '../../Editor/TextEditor';
import { Axios } from '../../components/common/Axios';
import { Construction } from '@mui/icons-material';
import { notify, notifyErr } from '../../components/common/norify';
import { useNavigate } from 'react-router-dom';


const SubServiceAdd = () => {

    const navigate = useNavigate()

    const [name, setName] = useState('');
    const [cta, setCTA] = useState('');
    const [heading, setHeading] = useState('');
    const [services, setServices] = useState([])
    const [title, setTitle] = useState('');
    const [updatedMedia, setUpdatedMedia] = useState('');
    const [ytId, setYtId] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [seo_title, setSeo_title] = useState('');
    const [canonical, setCanonical] = useState('');
    const [robot, setRobot] = useState('index,follow');
    const [script, setScript] = useState('');
    const [feature_image, setFeature_image] = useState('');
    const [seo_description, setSeo_description] = useState('');
    const [parentService, setParentService] = useState('');
    const [type, setType] = useState('');


    const addServiceSuccess = () => toast.info("Service Data Added Successfully", { position: "top-center", autoClose: 5000, });


    // /api/service/getServiceIdName

    const getData = async () => {
        try {
            const serviceData = await Axios.get(`/api/service/getServiceIdName`);
            let service = serviceData.data.data;
            setServices(service);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        const formData = new FormData();
        formData.append("media", JSON.stringify(updatedMedia));

        Axios.post('/api/service/sub-service', {
            name: name,
            cta: cta,
            title: title,
            heading: heading,
            media: type == 3 ? ytId : updatedMedia,
            content: updatedContent,
            description: updatedDescription,
            seo_title: seo_title,
            canonical: canonical,
            robot: robot,
            script: script,
            feature_image: feature_image,
            seo_description: seo_description,
            parentService: parentService,
            type: type,


        }, {
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                if (response.status === 200 || 201) {
                    console.log("Added Sub Service Data:", response.data);
                    notify("Sub Service Added successfully")
                    setTimeout(() => {
                        navigate('/sub-services')
                    }, 2000);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>Add Sub-Service</title>
                </Helmet>
                <section className="wrapper _blogs">
                    <div className="row">
                        <div className="col-lg-6">
                            <span className='p-3 fw-bold'>Add Sub-Service</span>
                        </div>

                    </div>
                </section>
                <section className='card mt-2'>
                    <div className='blog-d' >
                        <ToastContainer />
                        <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>

                            <Form.Group className=" mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label htmlFor="parentService">ParentService</Form.Label>
                                <Form.Select
                                    id="parentService"
                                    aria-label="Default select example"
                                    value={parentService}

                                    {...register("ParentService", {
                                        required: true,

                                        onChange: (e) => setParentService(e.target.value)
                                    })}
                                >
                                    <option value="" >Select Service</option>
                                    {services?.map((service, index) => (
                                        <option value={service._id} key={index}>{service.name}</option>
                                    ))}


                                </Form.Select>
                                {errors.ParentService && (
                                    <div className='errMsg text-danger'>ParentService is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" id="title" value={name}
                                    {...register("Name", {
                                        required: true,
                                        onChange: (e) => setName(e.target.value)
                                    })}
                                />

                                {errors.Name && (
                                    <div className='errMsg text-danger'>Name is required</div>
                                )}

                            </Form.Group>


                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Title" id="title" value={title}
                                    {...register("Title", {
                                        required: true,
                                        onChange: (e) => setTitle(e.target.value)
                                    })}
                                />

                                {errors.Title && (
                                    <div className='errMsg text-danger'>Title is required</div>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Heading</Form.Label>
                                <Form.Control type="text" placeholder="Heading" id="heading" value={heading}
                                    {...register("Heading", {
                                        required: true,
                                        onChange: (e) => setHeading(e.target.value)
                                    })}
                                />

                                {errors.Heading && (
                                    <div className='errMsg text-danger'>Heading is required</div>
                                )}

                            </Form.Group>




                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>CTA</Form.Label>
                                <Form.Control type="text" placeholder='CTA' value={cta}
                                    {...register("CTA", {
                                        required: true,
                                        onChange: (e) => setCTA(e.target.value)
                                    })}
                                />

                                {errors.CTA && (
                                    <div className='errMsg text-danger'>CTA is required</div>
                                )}

                            </Form.Group >

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label htmlFor="Type">Media Type</Form.Label>
                                <Form.Select
                                    id="Type"
                                    aria-label="Default select example"
                                    value={type}
                                    {...register("type", {
                                        required: true,
                                        onChange: (e) => setType(e.target.value)
                                    })}

                                >
                                    <option value="" >Select Type</option>
                                    <option value="1">Image</option>
                                    <option value="2">Video</option>
                                    <option value="3">YoutubeVideo</option>

                                </Form.Select>
                                {errors.type && (
                                    <div className='errMsg text-danger'>type is required</div>
                                )}
                            </Form.Group>



                            <Form.Group className="mb-3" >
                                {(type == 1 || type == 2) ?
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Form.Label>Media</Form.Label>
                                            <Form.Control type="file" name="file" accept={type == 2 ? 'video/mp4,video/x-m4v,video/*' : 'image/png,image/gif,image/jpeg,image/webp'}
                                                {...register("Media", {
                                                    required: true,
                                                    onChange: (e) => setUpdatedMedia(e.target.files[0])
                                                })}
                                            />

                                            {errors.Media && (
                                                <div className='errMsg text-danger'>Please Select Media</div>
                                            )}
                                        </div>
                                        <div className='col-lg-6 text-center mb-3 mt-3'>
                                            <Form.Label>Media Preview</Form.Label>
                                            <div className='p-3'>
                                                {updatedMedia === "" ?
                                                    <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                    :
                                                    (type == 1 ?
                                                        <img src={URL.createObjectURL(updatedMedia)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                        : type == 2 ?
                                                            <video src={URL.createObjectURL(updatedMedia)} controls style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                            : null)}
                                            </div>
                                        </div>
                                    </div> : type == 3 ?
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <Form.Label>Youtube Video Id</Form.Label>
                                                <Form.Control type="text" placeholder='Youtube Video Id' value={ytId}
                                                    {...register("YtID", {
                                                        required: true,
                                                        onChange: (e) => setYtId(e.target.value)
                                                    })}
                                                />

                                                {errors.YtID && (
                                                    <div className='errMsg text-danger'>'Youtube Video Id is required</div>
                                                )}
                                            </div>
                                            <div className='col-lg-6 text-center mb-3 mt-3'>
                                                <Form.Label>Media Preview</Form.Label>
                                                <div className='p-3'>
                                                    < iframe
                                                        src={`https://www.youtube.com/embed/VIDEO_ID?autoplay=1&controls=0&mute=1&loop=1&playlist=${ytId}&rel=0&modestbranding=1&showinfo=0`}
                                                        frameborder="0"
                                                        allowfullscreen
                                                        className="yt-video w-100"
                                                        style={{ pointerEvents: 'none', maxWidth: "300px", maxHeight: "300px" }}
                                                    ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </Form.Group>




                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Description</Form.Label>
                                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
                            </Form.Group>

                            <div className="col-lg-12">
                                <span className=' fw-bold'>SEO Section</span>
                            </div>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>SEO Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="SEO Title"
                                    value={seo_title}
                                    {...register("Seo_title", {
                                        required: true,
                                        onChange: (e) => setSeo_title(e.target.value)
                                    })}
                                />

                                {errors.Seo_title && (
                                    <div className='errMsg text-danger'>Seo title is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Canonical</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Canonical"
                                    value={canonical}
                                    onChange={(e) => setCanonical(e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label htmlFor="Robot">Robot</Form.Label>
                                <Form.Select
                                    id="Robot"
                                    aria-label="Default select example"
                                    value={robot}
                                    onChange={(e) => setRobot(e.target.value)}

                                >

                                    <option value="index,follow" >Index,Follow</option>
                                    <option value="index,no-follow">Index,No-Follow</option>
                                    <option value="no-index,follow">No-Index,Follow</option>
                                    <option value="no-index,no-follow">No-Index,No-Follow</option>
                                </Form.Select>

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Script</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Script"
                                    value={script}
                                    onChange={(e) => setScript(e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group
                                className="mb-3"

                            >

                                <Form.Label>Feature Image</Form.Label>
                                <Form.Control type="url" name="file"
                                    onChange={(e) => setFeature_image(e.target.value)} />


                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>SEO Description</Form.Label>
                                <Form.Control as="textarea" placeholder="SEO Description"
                                    value={seo_description}
                                    onChange={(e) => setSeo_description(e.target.value)} />

                            </Form.Group>



                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white' variant='contained'>
                                    Add Sub Service
                                </Button>
                            </div>
                        </Form>
                    </div>

                </section>




            </div>
        </>
    )
}

export default SubServiceAdd