import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { Axios } from '../components/common/Axios';
import { ToastContainer, toast } from 'react-toastify';
import { notify, notifyErr } from '../components/common/norify';



const TestimonialAdd = () => {
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [rating, setRating] = useState('');
  const [image, setImage] = useState('');
  const [addContent, setAddedContent] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    image: '',
    position: '',
    content: '',
    rating: ''
  })
  const [updateFormData, setUpdateFormData] = useState({
    name: '',
    image: '',
    position: '',
    content: '',
    rating: ''
  })
  const handleClose = () => {
    setName('');
    setPosition('');
    setImage('');
    setAddedContent('');
    setRating('');


  }



  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = () => {
    const formData = new FormData();
    formData.append("image", image);

    Axios.post('/api/testimonial', {
      name: name,
      position: position,
      image: image,
      content: addContent,
      rating: rating

    }, {
      headers: {

        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          console.log("Added testimonial Data:", response.data);
          notify("Testimonial Added successfully")
          // addTestimonialSuccess();
          setTimeout(() => {
            window.location.reload(true);
          }, 1500);
        }

        setName('');
        setPosition('');
        setImage('');
        setAddedContent('');
        setRating('');
        handleClose();
      })
      .catch((err) => {
        console.error(err);
      });

  };

  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Add Testimonials</title>
        </Helmet>
        <section className="wrapper _blogs">
          <div className="row">
            <ToastContainer />
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>Add Testimonials</span>
            </div>
          </div>
        </section>
        <section className='card mt-2'>
          <div className='blog-d' >
            <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Title" id="name" value={name}
                  {...register("Name", {
                    required: true,
                    onChange: (e) => setName(e.target.value)
                  })}
                />

                {errors.Name && (
                  <div className='errMsg text-danger'>Name is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Position</Form.Label>
                <Form.Control type="text" placeholder="Position" id="position" value={position}
                  {...register("Position", {
                    required: true,
                    onChange: (e) => setPosition(e.target.value)
                  })}
                />

                {errors.Position && (
                  <div className='errMsg text-danger'>Position is required</div>
                )}

              </Form.Group>


              <Form.Group
                className="mb-3"

              >

                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="file" id="file"
                      {...register("Image", {
                        required: true,
                        onChange: (e) => setImage(e.target.files[0])
                      })}
                    />

                    {errors.Image && (
                      <div className='errMsg text-danger'>Please Select Image</div>
                    )}

                  </div>

                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      {image == "" ?

                        <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        :
                        <img src={URL.createObjectURL(image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                      }
                    </div>
                  </div>
                </div>

              </Form.Group>


              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={addContent}
                  {...register("content", {
                    required: true,
                    onChange: (e) => setAddedContent(e.target.value)
                  })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="rating">Rating</Form.Label>
                <Form.Select
                  type="type"
                  id="rating"
                  placeholder="Rating"
                  value={rating}
                  {...register("rating", {
                    required: true,
                    onChange: (e) => setRating(e.target.value)
                  })}
                >
                  <option value="">Select Type</option>
                  <option value="1">1 start</option>
                  <option value="2">2 start</option>
                  <option value="3">3 start</option>
                  <option value="4">4 start</option>
                  <option value="5">5 start</option>
                </Form.Select>
                {errors.rating && (
                  <div className="errMsg text-danger">Please Select Rating</div>
                )}
              </Form.Group>


              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white' variant='contained'>
                  Add Testimonials
                </Button>
              </div>
            </Form>
          </div>

        </section>

      </div>
    </>
  )
}

export default TestimonialAdd