import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa'
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useNavigate } from 'react-router-dom';


const PortfolioAdd = () => {
    const navigate = useNavigate();
    const [name, setName] = useState([]);
    const [pending, setPending] = useState(true);
    const [images, setImages] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    //const [longDes, setLongDes] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        subtitle: '',
        content: '',
        description: '',

    });



    //   const getData = () => {
    //     Axios.get('api/getCategory')
    //       .then((response) => {
    //         setCategories(response.data.data);
    //         setPending(false);
    //       })
    //       .catch((err) => {
    //         setPending(false);
    //         console.log(err);
    //       });
    //   };

    //   useEffect(() => {
    //     getData();
    //   }, []);


    const handleImageChange = (e) => {
        const files = e.target.files;
        const imagesArray = [];

        for (let index = 0; index < files.length; index++) {
            const imageFile = files[index];
            imagesArray.push(imageFile);
        }

        // // Set the "image" field in the formData state to the array of images
        // setFormData({ ...formData, image: imagesArray });
        setImages(imagesArray);
    };



    const { register, handleSubmit, formState: { errors } } = useForm();
    // const onSubmit = (data) => {
    //   console.log(data)
    // }




    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {

        handleInputChange('content', updatedContent);
        handleInputChange('description', updatedDescription);
    }, [updatedContent, updatedDescription])

    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };



    const onSubmit = async () => {
        const imagesToUpload = images;
        const formDataToPost = new FormData();

        formDataToPost.append('name', formData.name);
        formDataToPost.append('subtitle', formData.subtitle);
        formDataToPost.append('content', formData.content);
        formDataToPost.append('description', formData.description);


        for (let index = 0; index < imagesToUpload.length; index++) {
            formDataToPost.append('image', imagesToUpload[index]);
        }


        try {
            const response = await Axios.post('api/portfolio', formDataToPost,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Portfolio Added Successfully');
                setTimeout(() => {
                    navigate('/portfolio')
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }

    }

    return (
        <>
            <div className='addportfolio-page'>
                <Helmet>
                    <title>Add Portfolio</title>
                </Helmet>
                <section className="portfolio-add">
                    <div className="d-flex justify-content-between align-items-center pt-6 pb-2 m-2">
                        <div className="">
                            <span className='fw-bold'>Add Portfolio</span>
                        </div>

                    </div>
                </section>
                <ToastContainer />
                <section classNAme="card  cardSectionBorder mt-3">
                    <div className='m-2'>
                        <Form className='row' onSubmit={handleSubmit(onSubmit)}>

                            {/* <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label htmlFor="category">Category</Form.Label>
                                <Form.Select
                                    id="category"
                                    aria-label="Default select example"
                                    value={formData?.category_id || ''}

                                    {...register("Category", {
                                        required: true,
                                        onChange: (e) => handleInputChange('category_id', e.target.value)
                                    })}
                                >
                                    <option value="" disabled>Select Category</option>
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Form.Select>

                                {errors.Category && (
                                    <div className='errMsg text-danger'>Category is required</div>
                                )}
                            </Form.Group> */}

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    value={formData?.name || ''}
                                    {...register("name", {
                                        required: true,
                                        onChange: (e) => handleInputChange('name', e.target.value)
                                    })}
                                />

                                {errors.name && (
                                    <div className='errMsg text-danger'>Please Provide Name</div>
                                )}
                            </Form.Group>

                            {/* <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label htmlFor="Featured">Featured</Form.Label>
                                <Form.Select
                                    id="Featured"
                                    aria-label="Default select example"
                                    value={formData?.featured || ''}
                                    {...register("Featured", {
                                        required: false,
                                        onChange: (e) => handleInputChange('featured', e.target.value)
                                    })}


                                >
                                    <option value="" disabled>Select Featured</option>
                                    <option value="1" >No</option>
                                    <option value="0">Yes</option>

                                </Form.Select>
                                {errors.Featured && (
                                    <div className='errMsg text-danger'>Featured is required</div>
                                )}
                            </Form.Group> */}


                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Subtitle</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Subtitle"
                                    value={formData?.subtitle || ''}
                                    {...register("subtitle", {
                                        required: true,
                                        onChange: (e) => handleInputChange('subtitle', e.target.value)
                                    })}
                                />

                                {errors.subtitle && (
                                    <div className='errMsg text-danger'>Please Provide Sub Title</div>
                                )}
                            </Form.Group>

                            {/* <Form.Group className="mt-2 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <Form.Control className="" rows="5" as="textarea"
                                    value={formData?.content || ''}
                                    {...register("content", {
                                        required: true,
                                        onChange: (e) => handleInputChange('content', e.target.value)
                                    })}
                                />

                                {errors.content && (
                                    <div className='errMsg text-danger'>Please Provide Content</div>
                                )}

                            </Form.Group>



                            <Form.Group className="mt-2 col-lg-6 col-md-12 col-sm-12 col-12">
                                <Form.Label>Description</Form.Label>
                                <Form.Control className="" rows="5" as="textarea"
                                    value={formData?.description || ''}
                                    {...register("description", {
                                        required: true,
                                        onChange: (e) => handleInputChange('description', e.target.value)
                                    })}
                                />

                                {errors.description && (
                                    <div className='errMsg text-danger'>Please Provide Description</div>
                                )}

                            </Form.Group> */}

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Description</Form.Label>
                                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
                            </Form.Group>

                            {/* <Form.Group className="mt-2 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Long Description</Form.Label>
                                <TextEditor data={longDes} setUpdatedContent={setLongDes} />
                            </Form.Group> */}

                            <Form.Group
                                className="mt-2"
                            >

                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <Form.Label>Image</Form.Label>
                                        <Form.Control type="file" name="file" multiple
                                            {...register("Image", {
                                                required: true,
                                                onChange: (e) => handleImageChange(e)
                                            })}
                                        />

                                        {errors.Image && (
                                            <div className='errMsg text-danger'>Please Select Image</div>
                                        )}

                                    </div>

                                    <div className='col-lg-12 mb-3 mt-5'>
                                        <div className='d-flex justify-content-start gap-3 align-items-center'>
                                            <Form.Label>Image Preview</Form.Label>
                                            {images?.length > 0 &&
                                                <Button className='text-white w-20 bg-danger' variant='contained'
                                                    onClick={() => setImages('')}
                                                >
                                                    <FaTrashAlt /> Clear
                                                </Button>}
                                        </div>
                                        <div className='p-3 text-center'>
                                            {images?.length > 0 ?
                                                <ImageList cols={6}>
                                                    {images?.map((item, index) => (
                                                        <ImageListItem key={index} style={{ border: '2px solid #2e2d3c' }}>
                                                            <img
                                                                srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                                // src={`${URL.createObjectURL(item)}?w=164&h=164&fit=crop&auto=format`}
                                                                src={URL.createObjectURL(item)}
                                                                alt={`image-${index}`}
                                                                loading="lazy" className='object-fit-contain'
                                                            />
                                                        </ImageListItem>
                                                    ))}
                                                </ImageList>
                                                : <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                            }


                                            {/* {updatedImage == "" ?

                        <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                        :
                        <img src={URL.createObjectURL(updatedImage)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                      } */}
                                        </div>

                                    </div>
                                </div>

                            </Form.Group>

                            <hr></hr>
                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#0C0833d', borderRadius: '1' }} >
                                    Add Portfolio
                                </Button>
                            </div>
                        </Form>





                    </div>
                </section>

            </div>
        </>
    )
}

export default PortfolioAdd