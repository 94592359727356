import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Router from './routes';
import { ThemeProvider } from 'styled-components';
import { theme } from './Themes/CustomTheme';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  // <RouterProvider router={Router}>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>


  // </RouterProvider>

  // </React.StrictMode>
);
