import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { Axios } from '../components/common/Axios';
import { ToastContainer, toast } from 'react-toastify';



const PopupAdd = () => {
    const [title, setTitle] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    //const [position, setPosition] = useState('');
    const [image, setImage] = useState('');
    const [btn_text, setBtntext] = useState('');
    const [btn_link, setBtnlink] = useState('');
    const [delay, setDelay] = useState('');
    const [addContent, setAddedContent] = useState('');
    const [type, setType] = useState('');
    const [formData, setFormData] = useState({
        title: '',
        btn_text: '',
        btn_link: '',
        image: '',
        content: '',
        type: '',
        delay: ''
    })
    const [updateFormData, setUpdateFormData] = useState({
        title: '',
        btn_txt: '',
        btn_link: '',
        image: '',
        content: '',
        type: '',
        delay: ''
    })
    const handleClose = () => {
        setTitle('');
        setBtntext('');
        setBtnlink('');
        setImage('');
        setAddedContent('');
        setType('');
        setDelay('');
    }



    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = () => {
        const formData = new FormData();
        formData.append("image", image);

        Axios.post('/api/popup', {
            title: title,
            btn_text: btn_text,
            btn_link: btn_link,
            image: image,
            content: addContent,
            type: type,
            delay: delay

        }, {
            headers: {

                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    // addTestimonialSuccess();
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 1500);
                }

                setTitle('');
                setBtntext('');
                setBtnlink('');
                setImage('');
                setAddedContent('');
                setType('');
                setDelay('');
                handleClose();
            })
            .catch((err) => {
                console.error(err);
            });

    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        // Reset values when option changes
        setBtntext('');
        setBtnlink('');
    };

    return (
        <>
            <div className='popup-page'>
                <Helmet>
                    <title>Add Popup</title>
                </Helmet>
                <section className="wrapper _blogs">
                    <div className="row">
                        <ToastContainer />
                        <div className="col-lg-12">
                            <span className='p-3 fw-bold'>Add Popup</span>
                        </div>
                    </div>
                </section>
                <section className='card mt-2'>
                    <div className='blog-d' >
                        <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Title" id="title" value={title}
                                    {...register("Title", {
                                        required: true,
                                        onChange: (e) => setTitle(e.target.value)
                                    })}
                                />

                                {errors.Name && (
                                    <div className='errMsg text-danger'>Title is required</div>
                                )}

                            </Form.Group>

                            {/* form */}




                            <Form.Group className="mt-2 mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label htmlFor="Type">Type</Form.Label>
                                <Form.Select
                                    id="type"
                                    aria-label="Default select example"
                                    value={type}
                                    {...register("type", {
                                        required: true,
                                        onChange: (e) => setType(e.target.value)
                                    })}

                                >
                                    <option value="" >Select Type</option>
                                    <option value="1">Form</option>
                                    <option value="2">Button</option>


                                </Form.Select>
                                {errors.type && (
                                    <div className='errMsg text-danger'>type is required</div>
                                )}
                            </Form.Group>
                            {type === "2" && (
                                <Form.Group className="mb-3">
                                    <Form.Label>Btn_Text</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Text"
                                        value={btn_text}
                                        onChange={(e) => setBtntext('btn_text', e.target.value)}
                                    />
                                    <Form.Label>Btn_Link</Form.Label>
                                    <Form.Control
                                        type="link"
                                        placeholder="link"
                                        value={btn_link}
                                        onChange={(e) => setBtnlink('btn_link', e.target.value)}
                                    />
                                </Form.Group>

                            )}
                            <Form.Group
                                className="mb-3"

                            >
                                {/* <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <Form.Label>Select Option</Form.Label>
                                    <Form.Control as="select" onChange={handleOptionChange}>
                                        <option value="">Select</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Form.Control>
                                </Form.Group>

                                {selectedOption === 'yes' && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>Btn_Text</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Text"
                                            value={btn_text}
                                            onChange={(e) => setBtntext(e.target.value)}
                                        />
                                    </Form.Group>
                                )}

                                {selectedOption === 'no' && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>Btn_Link</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Link"
                                            value={btn_link}
                                            onChange={(e) => setBtnlink(e.target.value)}
                                        />
                                    </Form.Group>
                                )} */}

                                {/* <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <Form.Label>Btn_Text</Form.Label>
                                    <Form.Control type="text" placeholder='text' value={btn_text}
                                        {...register("Btn_Text", {
                                            required: false,
                                            onChange: (e) => setBtntext(e.target.value)
                                        })}
                                    />

                                    {errors.Btn_Text && (
                                        <div className='errMsg text-danger'>Btn_Text is required</div>
                                    )}

                                </Form.Group >

                                <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <Form.Label>Btn_Link</Form.Label>
                                    <Form.Control type="text" placeholder='text' value={btn_link}
                                        {...register("Btn_Link", {
                                            required: false,
                                            onChange: (e) => setBtntext(e.target.value)
                                        })}
                                    />

                                    {errors.Btn_Link && (
                                        <div className='errMsg text-danger'>Btn_Link is required</div>
                                    )}

                                </Form.Group > */}


                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <Form.Label>Image</Form.Label>
                                        <Form.Control type="file" id="file"
                                            {...register("Image", {
                                                required: true,
                                                onChange: (e) => setImage(e.target.files[0])
                                            })}
                                        />

                                        {errors.Image && (
                                            <div className='errMsg text-danger'>Please Select Image</div>
                                        )}

                                    </div>

                                    <div className='col-lg-6 text-center mb-3 mt-3'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            {image == "" ?

                                                <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                :
                                                <img src={URL.createObjectURL(image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </Form.Group>


                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={addContent}
                                    {...register("content", {
                                        required: true,
                                        onChange: (e) => setAddedContent(e.target.value)
                                    })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 col-12">
                                <Form.Label>Delay</Form.Label>
                                <Form.Control type="delay" placeholder="delay" id="delay" value={delay}
                                    {...register("delay", {
                                        required: true,
                                        onChange: (e) => setDelay(e.target.value)
                                    })}
                                />

                                {errors.Delay && (
                                    <div className='errMsg text-danger'>Delay is required</div>
                                )}

                            </Form.Group>


                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white' variant='contained'>
                                    Add Popup
                                </Button>
                            </div>
                        </Form>
                    </div>

                </section>

            </div>
        </>
    )
}

export default PopupAdd;