import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { notify, notifyErr } from './norify';
import { Axios } from './Axios';


const EnquiryModel = ({ id, show, setShowUpdate }) => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
        service_id: '',
        website: '',
        reply: ''
    })


    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
            service_id: '',
            website: '',
            reply: ''
        })
        reset({
            name: '',
            email: '',
            message: '',
            phone: '',
            service_id: '',
            website: '',
            reply: ''

        });
    }


    const getSingleEnq = () => {
        Axios.get(`/api/contact/enquiry/${id}`)
            .then((response) => {
                const inquiryData = response.data.data[0];
                if (inquiryData) {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        ['name']: inquiryData.name,
                        ['email']: inquiryData.email,
                        ['message']: inquiryData.message,
                        ['website']: inquiryData.website,
                        ['service_id']: inquiryData.service_id,
                        ['phone']: inquiryData.phone,
                    }));

                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getSingleEnq();
    }, [id, show]);


    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };


    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async () => {
        try {
            const response = await Axios.put(`/api/contact/enquiryReply/${id}`, {
                reply: formData.reply
            });
            if (response.status === 200 || 201) {
                notify('Reply Sent Successfully');
                handleCloseUpdate();
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            console.error('Error:', error);
            notifyErr('Something went wrong');
        }
    }

    return (
        <>
            {/* Category Add Modal */}
            <Modal show={show} onHide={handleCloseUpdate} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Enquiry Reply</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Body>

                        <Form.Group className=" mb-3">

                            <Form.Control className="" type="text" placeholder="Name" id="username" value={formData.name} disabled
                                {...register("Name", {
                                    required: false,
                                    onChange: (e) => handleInputChange('name', e.target.value)
                                })}
                            />

                            {errors.Name && (
                                <div className='errMsg text-danger'>Name is required</div>
                            )}

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control disabled
                                className=""
                                type="tel"
                                placeholder="Phone"
                                value={formData.phone}
                                {...register("phone", {
                                    required: false,
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: "Invalid phone number"
                                    },
                                    onChange: (e) => handleInputChange('phone', e.target.value)
                                })}
                            />

                            {errors.phone && (
                                <div className='errMsg text-danger'>Phone is Required</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">

                            <Form.Control className="" type="email" placeholder="Email" value={formData.email} disabled
                                {...register("Email", {
                                    required: false,
                                    onChange: (e) => handleInputChange('email', e.target.value)
                                })}
                            />

                            {errors.Email && (
                                <div className='errMsg text-danger'>Email is Required</div>
                            )}

                        </Form.Group>

                        {(formData.website != undefined || formData.website != '') &&
                            <Form.Group className=" mb-3">

                                <Form.Control className="" type="text" placeholder="website" value={formData.website} disabled
                                    {...register("website", {
                                        required: false,
                                        onChange: (e) => handleInputChange('website', e.target.value)
                                    })}
                                />

                                {errors.website && (
                                    <div className='errMsg text-danger'>website is required</div>
                                )}

                            </Form.Group>
                        }

                        {(formData.service_id != undefined || formData.service_id != '') &&
                            <Form.Group className="mb-3">
                                <Form.Control className="" rows="3" as="textarea" placeholder="Service" value={formData.service_id} disabled
                                    {...register("Service", {
                                        required: false,
                                        onChange: (e) => handleInputChange('service_id', e.target.value)
                                    })}
                                />

                            </Form.Group>
                        }

                        {(formData.service_id != undefined || formData.service_id != '') &&
                            <Form.Group className="mb-3">

                                <Form.Control className="" rows="3" as="textarea" placeholder="Message" value={formData.message} disabled
                                    {...register("message", {
                                        required: false,
                                        onChange: (e) => handleInputChange('message', e.target.value)
                                    })}
                                />

                                {errors.message && (
                                    <div className='errMsg text-danger'>Message is Required</div>
                                )}

                            </Form.Group>
                        }


                        <Form.Group className="mb-3">

                            <Form.Control className="" rows="5" as="textarea" placeholder="Reply" value={formData.reply}
                                {...register("reply", {
                                    required: true,
                                    onChange: (e) => handleInputChange('reply', e.target.value)
                                })}
                            />

                            {errors.Enquiry && (
                                <div className='errMsg text-danger'>reply message is Required</div>
                            )}

                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='submit' className='text-white' variant='contained' style={{ backgroundColor: '#3e3d52' }} >
                            Reply
                        </Button>
                        <Button variant="secondary" onClick={handleCloseUpdate} >
                            Close
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    )
}

export default EnquiryModel