import React, { useState, useEffect, Component } from 'react';
import Datatables from '../DataTable/Datatables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { Axios } from '../components/common/Axios';
import Card from 'react-bootstrap/Card';
import Swal from "sweetalert2";
import { notify, notifyErr } from '../components/common/norify';

const Seo = () => {
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [show, setShow] = useState(false)
    const [id, setId] = useState('')
    const [formData, setFormData] = useState({
        seo_title: '',
        canonical: '',
        robot: '',
        script: '',
        feature_image: '',
        seo_description: '',
    })


    const getData = async () => {
        try {
            const serviceData = await Axios.get('/api/page');
            let service = serviceData.data.data;
            setData(service);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setPending(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleShow = (row) => {
        setShow(true);
        getSeo(row.role)
        setId(row._id);
    };

    const handleClose = () => {
        setShow(false);
        setFormData({
            seo_title: '',
            canonical: '',
            robot: '',
            script: '',
            feature_image: '',
            seo_description: '',
        })
        setId('');
    };


    const getSeo = async (role) => {
        try {
            const seoData = await Axios.get(`/api/page/${role}`);
            let seo = seoData.data.data;
            if (seo) {
                setFormData({
                    seo_title: seo.seo_title,
                    canonical: seo.canonical,
                    robot: seo.robot,
                    script: seo.script,
                    feature_image: seo.feature_image,
                    seo_description: seo.seo_description,
                })

            }
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }


    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const { register, handleSubmit, reset, formState: { errors: errors } } = useForm();
    const onSubmit = async () => {
        try {
            const response = await Axios.put(`/api/page/${id}`, formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });
            if (response.status === 200 || 201) {
                notify('SEO Updated Successfully');
                handleClose();
                setTimeout(() => {
                    window.location.reload(false);
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        } finally {
            setPending(false)
        }

    }

    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            {
                name: <th className='fs-6 '>Name</th>,
                selector: (row) => row.name,
                width: '200px',
            },
            {
                name: <th className='fs-6'>Heading</th>,
                selector: (row) => row.heading,
                width: '220px',
            },
            {
                name: <th className='fs-6'>Seo Title</th>,
                selector: (row) => row.seo_title,
                width: '220px',
            },
            {
                name: <th className='fs-6'>Canonical</th>,
                selector: (row) => row.canonical,
                width: '220px',
            },
            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>

                        <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}
                            onClick={() => handleShow(row)}
                        >
                            <FaEdit />
                        </Button>
                    </div>
                ),
                right: true

            },
        ],
    };

    return (
        <>
            <div className='service-page'>
                <Helmet>
                    <title>SEO</title>
                </Helmet>

                <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                    <ToastContainer />
                    <section className="wrapper _blogs">
                        <div className="row">
                            <div className="col-lg-6">
                                <span className='p-3 fw-bold'>SEO</span>
                            </div>

                            <div className='col-lg-6 pb-2 btn-d d-flex justify-content-end'>
                                {/* <Link to="/service-add">
                                    <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }} >
                                        Add Service
                                    </Button>
                                </Link> */}

                            </div>
                        </div>
                    </section>

                </section>

                <section className=' mt-2'>
                    <Card >
                        <div className="main " >
                            <Datatables data={data} columns={tableData.columns} />
                        </div>
                    </Card>

                </section>

            </div>

            {/* SEO Update Modal */}
            <Modal show={show} onHide={handleClose} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Update SEO</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Body>
                        <div className='row'>
                            <Form.Group className="mb-3 col-12">
                                <Form.Label>SEO Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="SEO Title"
                                    value={formData.seo_title}
                                    {...register("Seo_title", {
                                        required: false,
                                        onChange: (e) => handleInputChange('seo_title', e.target.value)
                                    })}
                                />

                                {errors.Seo_title && (
                                    <div className='errMsg text-danger'>Seo Title is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 col-12">
                                <Form.Label>Canonical</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Canonical"
                                    value={formData.canonical}
                                    onChange={(e) => handleInputChange('canonical', e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group className="mb-3 col-12">
                                <Form.Label htmlFor="Robot">Robot</Form.Label>
                                <Form.Select
                                    id="Robot"
                                    aria-label="Default select example"
                                    value={formData.robot}
                                    onChange={(e) => handleInputChange('robot', e.target.value)}

                                >

                                    <option value="index,follow" >Index,Follow</option>
                                    <option value="index,no-follow">Index,No-Follow</option>
                                    <option value="no-index,follow">No-Index,Follow</option>
                                    <option value="no-index,no-follow">No-Index,No-Follow</option>
                                </Form.Select>

                            </Form.Group>

                            <Form.Group className="mb-3 col-12">
                                <Form.Label>Script</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Script"
                                    value={formData.script}
                                    onChange={(e) => handleInputChange('script', e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group
                                className="mb-3 col-12"

                            >

                                <Form.Label>Feature Image</Form.Label>
                                <Form.Control type="url" name="file" value={formData.feature_image}
                                    onChange={(e) => handleInputChange('feature_image', e.target.value)} />


                            </Form.Group>

                            <Form.Group className="mb-3 col-12">
                                <Form.Label>SEO Description</Form.Label>
                                <Form.Control as="textarea" placeholder="SEO Description"
                                    value={formData.seo_description}
                                    onChange={(e) => handleInputChange('seo_description', e.target.value)} />

                            </Form.Group>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='submit' className='text-white' variant='contained' style={{ backgroundColor: '#0C0833' }} >
                            Update
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>


        </>
    )
}

export default Seo