import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';
import SubContentModel from '../../components/common/SubContentModel';

const Expertise = () => {


  const [pending, setPending] = useState(false)
  const [id, setId] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    heading: '',
    content: '',
    description: '',
    // cta: ''
  })

  const getData = async () => {
    try {
      const expertiseData = await Axios.get(`/api/section/seo/expertise`);
      let expertise = expertiseData.data.data;
      if (expertise) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['title']: expertise.title,
          ['heading']: expertise.heading,
          ['content']: expertise.content,
          ['description']: expertise.description,
          // ['cta']: expertise.cta,
        }))
        setId(expertise._id);
      }

    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    getData();
  }, []);


  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };


  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async () => {
    setPending(true);

    try {
      const response = await Axios.put(`/api/section/seo/expertise/${id}`, formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
      if (response.status === 200 || 201) {
        notify('Update Successfully');
        setTimeout(() => {
          window.location.reload(false)
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    }

  };

  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Expertise</title>
        </Helmet>
        <section className="wrapper _profile">
          <div className="row">
            <ToastContainer />
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>Expertise</span>
            </div>
          </div>
        </section>
        <section classNAme="card  cardSectionBorder mt-3">
          <div className='m-2'>
            <Form className='row m-0' onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.title}
                  {...register("title", {
                    required: false,
                    onChange: (e) => handleInputChange('title', e.target.value)
                  })}
                />

                {errors.title && (
                  <div className='errMsg text-danger'>title is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Heading</Form.Label>
                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.heading}
                  {...register("Heading", {
                    required: false,
                    onChange: (e) => handleInputChange('heading', e.target.value)
                  })}
                />

                {errors.Heading && (
                  <div className='errMsg text-danger'>Heading is required</div>
                )}

              </Form.Group>

              {/* <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                <Form.Label>CTA</Form.Label>
                <Form.Control type="text" placeholder='CTA' value={formData.cta}
                  {...register("CTA", {
                    required: false,
                    onChange: (e) => handleInputChange('cta', e.target.value)
                  })}
                />

                {errors.CTA && (
                  <div className='errMsg text-danger'>CTA is required</div>
                )}

              </Form.Group> */}

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <Form.Control as="textarea" placeholder="Content" rows={7}
                  value={formData.content}
                  onChange={(e) => handleInputChange('content', e.target.value)} />

              </Form.Group>


              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" placeholder="description" rows={7}
                  value={formData.description}
                  onChange={(e) => handleInputChange('description', e.target.value)} />

              </Form.Group>

              <hr></hr>
              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className=' Add_btn_ text-white' variant='contained'>
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </section>

        <section>
          <SubContentModel _id={id} type={4} />
        </section>

      </div>
    </>
  )
}

export default Expertise