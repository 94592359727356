import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../layouts/DashboardLayout';
import Login from '../pages/Login';

export default function ProtectedRoute({ authenticated, ...props }) {
  if (authenticated) {
    return <DashboardLayout />;
  } else {
    return <><Login /><Navigate to={'/'} /></>;
  }
};
