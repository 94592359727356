import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TextEditor from '../Editor/TextEditor';
import { useParams } from 'react-router-dom';
import { Axios } from '../components/common/Axios';


const TestimonialUpdate = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const { id } = useParams();
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [updatedImage, setImage] = useState('');
  const [image, setImageup] = useState('');
  const [rating, setRating] = useState('');
  const [addContent, setAddedContent] = useState('');
  const [show, setShow] = useState(false);


  const [file, setFile] = useState('');
  const [pending, setPending] = useState(true);
  const handleClose = () => {
    setName('');
    setPosition('');
    setImage('');
    setAddedContent('');
    setRating('');
    setShow(false);
  }

  const updateTestimonialError = () => toast.error("Something went wrong", { position: "top-center", autoClose: 5000 });

  const updateTestimonialSuccess = () => toast.info("Testimonial Updated Successfully", { position: "top-center", autoClose: 5000 });
  const { register, handleSubmit, formState: { errors } } = useForm();



  const getTestimonialData = () => {
    Axios.get(`/api/testimonial/${id}`)
      .then((response) => {
        const testimonialData = response.data;
        if (testimonialData) {
          setName(testimonialData.name);
          setPosition(testimonialData.position);
          setImageup(testimonialData.image);
          setAddedContent(testimonialData.content);
          setRating(testimonialData.rating);


        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTestimonialData();
    window.scrollTo(0, 0);
  }, [id]);

  const onUpdate = (testimonialData) => {
    setPending(true);

    const formData = new FormData();
    formData.append("file", updatedImage);


    Axios.put(`/api/testimonial/${id}`, {
      name: name,
      position: position,
      image: updatedImage,
      content: addContent,
      rating: rating,
    }, {
      headers: {

        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          updateTestimonialSuccess();
          setName('');
          setPosition('');
          setImage('');
          setAddedContent('');
          setRating('');
          handleClose();
          setTimeout(() => {
            window.location.reload(true);
          }, 1500);
        }
      })
      .catch((err) => {
        updateTestimonialError();
        console.error(err);
      });
  };

  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Update Testimonials</title>
        </Helmet>
        <section className="wrapper _blogs">
          <div className="row">
            <ToastContainer />
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>Update Testimonials</span>
            </div>
          </div>
        </section>
        <section className='card mt-2'>
          <div className='blog-d' >
            <Form className='row m-2' onSubmit={handleSubmit(onUpdate)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Title" id="name" value={name}
                  {...register("Name", {
                    required: false,
                    onChange: (e) => setName(e.target.value)
                  })}
                />

                {errors.Name && (
                  <div className='errMsg text-danger'>Name is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Position</Form.Label>
                <Form.Control type="text" placeholder="Heading" id="position"
                  value={position}
                  {...register("Position", {
                    required: false,
                    onChange: (e) => setPosition(e.target.value)
                  })}
                />

                {errors.Position && (
                  <div className='errMsg text-danger'>Position is required</div>
                )}

              </Form.Group>


              <Form.Group
                className="mb-3"

              >

                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="file" id="file"
                      {...register("Image", {
                        required: false,
                        onChange: (e) => setImage(e.target.files[0])
                      })}
                    />

                    {errors.Image && (
                      <div className='errMsg text-danger'>Please Select Image</div>
                    )}

                  </div>

                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      {updatedImage == "" ?

                        <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        :
                        <img src={URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                      }
                    </div>

                  </div>
                </div>

              </Form.Group>


              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={addContent}
                  {...register("content", {
                    required: true,
                    onChange: (e) => setAddedContent(e.target.value)
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="rating">Rating</Form.Label>
                <Form.Select
                  type="type"
                  id="rating"
                  placeholder="Rating"
                  value={rating}
                  {...register("rating", {
                    required: true,
                    onChange: (e) => setRating(e.target.value)
                  })}
                >
                  <option value="">Select Type</option>
                  <option value="1">1 start</option>
                  <option value="2">2 start</option>
                  <option value="3">3 start</option>
                  <option value="4">4 start</option>
                  <option value="5">5 start</option>
                </Form.Select>
                {errors.rating && (
                  <div className="errMsg text-danger">Please Select Rating</div>
                )}
              </Form.Group>



              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white' variant='contained'>
                  Update Testimonials
                </Button>
              </div>
            </Form>
          </div>

        </section>

      </div>
    </>
  )
}

export default TestimonialUpdate