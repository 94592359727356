import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Form from 'react-bootstrap/Form';

const Contact = () => {
  return (
    <>
      <div className='contact-page'>
        <section className='header-s'>
          <div className='header-d'>
            <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
              <Link className='text-decoration-none' to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">Contact</Typography>
            </Breadcrumbs>
          </div>
        </section>
        <section className='contact-s'>
          <div className='contact-d'>
            <Form className='row mt-5'>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="name@example.com" />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="name@example.com" />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="text" placeholder="name@example.com" />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="text" placeholder="name@example.com" />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" placeholder="name@example.com" />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Heading</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>


              <div className='btn-d d-flex justify-content-end'>
                <Button variant='contained'>
                  Update
                </Button>
              </div>

            </Form>
          </div>
        </section>
      </div>
    </>
  )
}

export default Contact