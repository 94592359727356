import React, { useState, useEffect, Component } from 'react';
import Datatables from '../../DataTable/Datatables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { Axios } from '../../components/common/Axios';
import Card from 'react-bootstrap/Card';
import Swal from "sweetalert2";


const SubServices = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [data, setblogs] = useState([]);
    const [pending, setPending] = useState(true);
    const [id, setId] = useState('')

    const getSubServices = () => {
        Axios.get('api/service/sub-service').then((response) => {
            console.log(response.data.data);
            setblogs(response.data.data);
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }

    useEffect(() => {
        getSubServices();
    }, []);


    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            {
                name: <th className='fs-6 '>Name</th>,
                selector: (row) => row.name,
                width: '200px',
            },
            {
                name: <th className='fs-6 '>Cta</th>,
                selector: (row) => row.cta,
                width: '200px',
            },
            {
                name: <th className='fs-6'>Heading</th>,
                selector: (row) => row.heading,
                width: '220px',
            },

            {
                name: <th className='fs-6'>Media</th>,
                // selector: (row) => (
                //     <img width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.media}`} />
                // ),
                selector: (row) => (
                    <>
                        {row.type == 1 && <img width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.media}`} alt="Portfolio Image" />}
                        {row.type == 2 && <video width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.media}`} />}
                        {row.type == 3 && row.media}
                    </>
                ),
                width: '160px',
            },
            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <Link to={`/subservice-update/${row._id}`}>
                            <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}>
                                <FaEdit />
                            </Button>
                        </Link>
                        <Button className='_delete_btn_ text-white bg-danger' onClick={() => confirmDelete(row._id)} variant='contained' style={{ float: 'right' }}><FaTrashAlt /></Button>
                    </div>
                ),
                right: true,

            },
        ],
    };


    const handleDelete = async (id) => {
        try {
            const response = await Axios.delete(`/api/service/sub-service/${id}`);
            if (response.status === 200) {
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }

            Swal.fire({
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                icon: 'success',
                confirmButtonColor: '#0C0833',
            });
        } catch (error) {
            console.error(error);

            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the data.',
                icon: 'error',
            });
        }
    };

    const confirmDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0C0833',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {

            if (result.value) {
                console.log(result)

                handleDelete(id);
            }
        });
    };
    return (
        <div className='profile-page'>
            <Helmet>
                <title>Sub Services</title>
            </Helmet>

            <ToastContainer />
            <section className="wrapper _subservices">
                <div className="row">
                    <div className="col-lg-6">
                        <span className='p-3 fw-bold'>Sub Services</span>
                    </div>
                    <div className='col-lg-6 pb-2 btn-d d-flex justify-content-end'>
                        <Link to="/subservice-add">
                            <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }} >
                                Add SubService
                            </Button>
                        </Link>

                    </div>


                </div>
            </section>

            <section className=' mt-2'>
                <Card >
                    <div className="main ">
                        <Datatables data={data} columns={tableData.columns} />
                    </div>
                </Card>

            </section>
        </div>

    )
}

export default SubServices